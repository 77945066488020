var formatter = {

    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>';
    },

    euro: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data + " €";
    }

};