var crud = function (options) {

    var crud = this;

    this.isNew =function ($form) {
        return !(parseInt($form.attr("data-id")) > 0);
    };

    this.formSubmit = function (url, form, additionslParams, success, error) {
        success = success || function() {};
        error = error || function() {};
        form = form || undefined;
        var $form = form;
        if(!form){
            app.warning("form undefined");
            return;
        }

        if(typeof form == 'string')
            $form = $(form);
        else
            $form = form; //oggetto

        var $method = null;

        if(!crud.isNew($form)){
            $method = $.put;
            url += "/" + $form.attr("data-id");
        }else{
            $method = $.post;
        }

        $form.find('.error-block').html("").addClass("invisible");

        var param = lib.formSerialize($form);
        param = $.extend({}, param, additionslParams);

        $method(url, param)
            .done(function(data) {
                success(data);
            })
            .fail(function(data) {
                if(data.status == 422) {
                    var errors = data.responseJSON;
                    Object.keys(errors).forEach(function(k) {
                        var $error = undefined;
                        var name = k;
                        if(k.indexOf(".") > -1) {
                            // l'elemento è un array
                            name = k.replace(".", "[") + "]";
                        }
                        if($form.find('[data-name="' + name + '"]').length > 0) {
                            $error = $form.find('[data-name="' + name + '"]');
                            $error.html(errors[k]);
                            $error.removeClass("invisible");
                        } else {
                            // lo spazio per mostrare l'errore non c'è. Lo creo
                            var $field = $('[name="' + name + '"]');
                            if($field) {
                                var $parent = $field.parent();
                                $error = $('<span class="error-block label label-danger" data-name="' + name + '">' + errors[k] + '</span>');
                                $parent.append($error);
                            }
                        }
                    });
                }
                error(data);
            });
    };

    this.successDelete = function (form, data, reload, href, callback, hideLoader) {
        if (data.response) {
            if (reload) app.reload();
            if (href) app.href(href);
        } else {
            app.warning("", data.message);
        }
        if (callback) {
            window[callback](data);
        }
        if (!hideLoader)
            app.block(0);
    };

    this.successSave = function (form, data, reload, href, callback, hideLoader) {
        if (data.response) {
            app.success("", "Operazione eseguita correttamente!");

            if (reload) {
                if (crud.isNew(form)) {
                    app.href(form.attr('action') + '/' + data.message + '/edit' + app.getUrlParams(window.location.href));		// TODO dedirtizzare
                } else {
                    app.reload();
                }
            }
            if (href) app.href(href);
        } else {
            app.warning("", data.message);
        }

        if (typeof options.callback != 'undefined') {
            options.callback(data);
        } else {
            if (typeof callback != 'undefined') {
                var fnz = app.eval(callback);
                fnz(data);
            }
        }

        if (!hideLoader)
            app.block(0);
    };

    this.bindEvents = function () {
        var idForm = options.form;

        $("#" + idForm).find('[data-interaction=delete]').unbind('click').bind('click', function (e) {
            e.preventDefault();

            if (confirm("Sei sicuro di voler eliminare l'elemento?")) {
                var $form = $("#" + options.form);
                var url = $(this).attr('data-url') || ($form.attr("action") + "/" + $form.attr("data-id"));
                var error = $(this).attr('data-error');
                var reload = $(this).attr('data-reload');
                var href = $(this).attr('data-href');
                var callback = $(this).attr('data-callback');
                var hideLoader = $(this).attr('data-hideloader') || false;

                if (!hideLoader)
                    app.block(1);
                $.delete(url)
                    .success(function (data) {
                        crud.successDelete($("#" + idForm), data, reload, href, callback, hideLoader);
                    })
                    .error(function () {
                        if (!hideLoader)
                            app.block(0);
                        app.error('', 'Delete error!');
                    });
            }
        });

        $("#" + idForm).find('[data-interaction=save]').unbind('click').bind('click', function (e) {
            e.preventDefault();

            var error = $(this).attr('data-error');
            var reload = $(this).attr('data-reload');
            var href = $(this).attr('data-href');
            var hideLoader = $(this).attr('data-hideloader') || false;
            var callback = $(this).attr('data-callback');

            if (!hideLoader)
                app.block(1);

            var $form = $("#" + idForm);

            crud.formSubmit($form.attr("action"), $form, {}, function (data) {
                crud.successSave($("#" + idForm), data, reload, href, callback, hideLoader);
            }, function () {
                app.error("", error);
                if (!hideLoader)
                    app.block(0);
            });
        });

        $('[data-interaction="save-top"]').unbind('click').bind('click', function () {
            var target = $(this).attr('data-target');

            $("#" + target).find('[data-interaction=save]').trigger('click');
        });

        $('[data-interaction="delete-top"]').unbind('click').bind('click', function () {
            var target = $(this).attr('data-target');

            $("#" + target).find('[data-interaction=delete]').trigger('click');
        });
    };

    this.bindEvents();
};